import './App.css';
import './confetti.css';
import React, { Component }  from 'react';
import { useState, useEffect } from 'react';
import logo from './logo.png';



function App() {
  const colors = ['aliceblue', 'tomato', 'darksalmon', 'thistle', 'olivedrab', 'steelblue']
  
  var dp = [];
  for(let i=0;i<14;i++){
    let temp = []
    for(let j=0;j<14;j++){
      temp[j] = colors[Math.floor(Math.random() * 6)]
    }
    dp.push(temp)
  }

  var [state, setState] = useState({
    boardColors: dp,
    covered : [{x:0,y:0}],
  })
  var [count, setCount] = useState(30)
  var [isLevelOver, setIsLevelOver] = useState(false)
  var [level, setLevel] = useState(0)

  useEffect(()=> {
    setCount(30-level)
    setIsLevelOver(false)
  }, [level])
  useEffect(() => {
    console.log(state.covered.length)
    console.log(state)
  }, [state])
  useEffect(() => {
    changeState(state.boardColors[0][0])
  }, [])

  var resetBoard = () => {
    let dp = [];
    for(let i=0;i<14;i++){
      let temp = []
      for(let j=0;j<14;j++){
        temp[j] = colors[Math.floor(Math.random() * 6)]
      }
      dp.push(temp)
    }
    setState((state) => ({
      boardColors: dp,
      covered: [{x:0,y:0}],
    }))
    setCount(30)
    setLevel(0)
    changeState(state.boardColors[0][0])
  }

  var nextLevel = () => {
    setLevel((level)=>(level+1))
    let dp = [];
    for(let i=0;i<14;i++){
      let temp = []
      for(let j=0;j<14;j++){
        temp[j] = colors[Math.floor(Math.random() * 6)]
      }
      dp.push(temp)
    }
    setState((state) => ({
      boardColors: dp,
      covered: [{x:0,y:0}],
    }))
    changeState(state.boardColors[0][0])
  }
  var decreaseMoves = () => {
    setCount((count) => count-1)
  }

  var changeState = (color) => {
    
    setState((state) => updateState(state))
    
    function updateState(state){
      
      function isnotcovered(l,m,xt){
        for(let i=0;i<xt.length;i++){
          if(xt[i].x===l && xt[i].y===m){
            return false;
          }
        }
        return true;
      }

      var temp2D = [...state.boardColors];
      for(let i=0; i<state.covered.length; i++){
        temp2D[state.covered[i].x][state.covered[i].y]=color
      }
      var q = [...state.covered]
      var append_arr = [...state.covered]
      while(q.length){
        let top = q.shift()
        let l = top.x;
        let m = top.y;
        if( m-1>=0 && temp2D[l][m-1]===color && isnotcovered(l,m-1,append_arr)){
          q.push({x:l,y:m-1})
          append_arr.push({x:l,y:m-1})
        }
        if(l+1<14  && temp2D[l+1][m]===color && isnotcovered(l+1,m,append_arr)){
          q.push({x:l+1,y:m})
          append_arr.push({x:l+1,y:m})
        }
        if(m+1<14 && temp2D[l][m+1]===color && isnotcovered(l,m+1,append_arr)){
          q.push({x:l,y:m+1})
          append_arr.push({x:l,y:m+1})
        }
        if(l-1>=0 && temp2D[l-1][m]===color && isnotcovered(l-1,m,append_arr)){
          q.push({x:l-1,y:m})
          append_arr.push({x:l-1,y:m})
        }
      }
      if(append_arr.length === 196){
        setIsLevelOver(true)
      }
      return {
        boardColors : temp2D,
        covered : [...append_arr],
      }
    }
  }

  
  return (
    <>
    <div className='Banner'>
     <img className='logoimage' height = '110px' src={logo} alt="Logo"></img>
    </div>
    <div className='container'>
      <div className='infobox'>
        Fill the board with a single color in the given number of moves to move to the next level.
      </div>
      <div className='React-Main'>
        <Board colors2D={state.boardColors} movesLeft={count} isLevelOver={isLevelOver} resetBoard={resetBoard} nextLevel={nextLevel}/>
        <Controls boardColors={state.boardColors} colors2D={state.boardColors} colors={colors} color='pink' changeState={changeState} resetBoard={resetBoard} movesLeft={count} decreaseMoves={decreaseMoves} isLevelOver={isLevelOver} nextLevel={nextLevel}
        level={level}/>
      </div>
    </div>
    </>
  );
}


function Board(props){
  
  var display = props.colors2D.map((color1D, index1) => (color1D.map((color,index2) => <div key={14*index1 + index2} style={{width:'calc(100% / 14)', aspectRatio: '1', backgroundColor: color, float: 'left'}}></div>)))
  return(
    <div className='boardStyle'>
      {display}
      {(props.movesLeft===0 && !props.isLevelOver) ? 
          <div className='level-fail'>
            <div className='message'>
              <p className='fail-style'>Level Failed!</p>
              <button className='button-fail' onClick={props.resetBoard}>Try Again</button>
            </div>
          </div> : 
          <>
          </>
      }
      {(props.isLevelOver) ? 
          <div className='wrapper'>
          <div className="confetti-149"></div>
          <div className="confetti-148"></div>
          <div className="confetti-147"></div>
          <div className="confetti-146"></div>
          <div className="confetti-145"></div>
          <div className="confetti-144"></div>
          <div className="confetti-143"></div>
          <div className="confetti-142"></div>
          <div className="confetti-141"></div>
          <div className="confetti-140"></div>
          <div className="confetti-139"></div>
          <div className="confetti-138"></div>
          <div className="confetti-137"></div>
          <div className="confetti-136"></div>
          <div className="confetti-135"></div>
          <div className="confetti-134"></div>
          <div className="confetti-133"></div>
          <div className="confetti-132"></div>
          <div className="confetti-131"></div>
          <div className="confetti-130"></div>
          <div className="confetti-129"></div>
          <div className="confetti-128"></div>
          <div className="confetti-127"></div>
          <div className="confetti-126"></div>
          <div className="confetti-125"></div>
          <div className="confetti-124"></div>
          <div className="confetti-123"></div>
          <div className="confetti-122"></div>
          <div className="confetti-121"></div>
          <div className="confetti-120"></div>
          <div className="confetti-119"></div>
          <div className="confetti-118"></div>
          <div className="confetti-117"></div>
          <div className="confetti-116"></div>
          <div className="confetti-115"></div>
          <div className="confetti-114"></div>
          <div className="confetti-113"></div>
          <div className="confetti-112"></div>
          <div className="confetti-111"></div>
          <div className="confetti-110"></div>
          <div className="confetti-109"></div>
          <div className="confetti-108"></div>
          <div className="confetti-107"></div>
          <div className="confetti-106"></div>
          <div className="confetti-105"></div>
          <div className="confetti-104"></div>
          <div className="confetti-103"></div>
          <div className="confetti-102"></div>
          <div className="confetti-101"></div>
          <div className="confetti-100"></div>
          <div className="confetti-99"></div>
          <div className="confetti-98"></div>
          <div className="confetti-97"></div>
          <div className="confetti-96"></div>
          <div className="confetti-95"></div>
          <div className="confetti-94"></div>
          <div className="confetti-93"></div>
          <div className="confetti-92"></div>
          <div className="confetti-91"></div>
          <div className="confetti-90"></div>
          <div className="confetti-89"></div>
          <div className="confetti-88"></div>
          <div className="confetti-87"></div>
          <div className="confetti-86"></div>
          <div className="confetti-85"></div>
          <div className="confetti-84"></div>
          <div className="confetti-83"></div>
          <div className="confetti-82"></div>
          <div className="confetti-81"></div>
          <div className="confetti-80"></div>
          <div className="confetti-79"></div>
          <div className="confetti-78"></div>
          <div className="confetti-77"></div>
          <div className="confetti-76"></div>
          <div className="confetti-75"></div>
          <div className="confetti-74"></div>
          <div className="confetti-73"></div>
          <div className="confetti-72"></div>
          <div className="confetti-71"></div>
          <div className="confetti-70"></div>
          <div className="confetti-69"></div>
          <div className="confetti-68"></div>
          <div className="confetti-67"></div>
          <div className="confetti-66"></div>
          <div className="confetti-65"></div>
          <div className="confetti-64"></div>
          <div className="confetti-63"></div>
          <div className="confetti-62"></div>
          <div className="confetti-61"></div>
          <div className="confetti-60"></div>
          <div className="confetti-59"></div>
          <div className="confetti-58"></div>
          <div className="confetti-57"></div>
          <div className="confetti-56"></div>
          <div className="confetti-55"></div>
          <div className="confetti-54"></div>
          <div className="confetti-53"></div>
          <div className="confetti-52"></div>
          <div className="confetti-51"></div>
          <div className="confetti-50"></div>
          <div className="confetti-49"></div>
          <div className="confetti-48"></div>
          <div className="confetti-47"></div>
          <div className="confetti-46"></div>
          <div className="confetti-45"></div>
          <div className="confetti-44"></div>
          <div className="confetti-43"></div>
          <div className="confetti-42"></div>
          <div className="confetti-41"></div>
          <div className="confetti-40"></div>
          <div className="confetti-39"></div>
          <div className="confetti-38"></div>
          <div className="confetti-37"></div>
          <div className="confetti-36"></div>
          <div className="confetti-35"></div>
          <div className="confetti-34"></div>
          <div className="confetti-33"></div>
          <div className="confetti-32"></div>
          <div className="confetti-31"></div>
          <div className="confetti-30"></div>
          <div className="confetti-29"></div>
          <div className="confetti-28"></div>
          <div className="confetti-27"></div>
          <div className="confetti-26"></div>
          <div className="confetti-25"></div>
          <div className="confetti-24"></div>
          <div className="confetti-23"></div>
          <div className="confetti-22"></div>
          <div className="confetti-21"></div>
          <div className="confetti-20"></div>
          <div className="confetti-19"></div>
          <div className="confetti-18"></div>
          <div className="confetti-17"></div>
          <div className="confetti-16"></div>
          <div className="confetti-15"></div>
          <div className="confetti-14"></div>
          <div className="confetti-13"></div>
          <div className="confetti-12"></div>
          <div className="confetti-11"></div>
          <div className="confetti-10"></div>
          <div className="confetti-9"></div>
          <div className="confetti-8"></div>
          <div className="confetti-7"></div>
          <div className="confetti-6"></div>
          <div className="confetti-5"></div>
          <div className="confetti-4"></div>
          <div className="confetti-3"></div>
          <div className="confetti-2"></div>
          <div className="confetti-1"></div>
          <div className="confetti-0"></div>
         
          <div className='level-pass'>
            <div className='message'>
              <p className='pass-style'>Hurray! Level Success</p>
              <button className='button-success' onClick={props.nextLevel}>Next Level</button>
            </div>
          </div>
          </div> : 
          <>
          </>
      }
    </div>
  )
}

function Controls(props){

  var controlStyle = {
    width: '150px',
    backgroundColor: '#282c34',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }

  var handleClick = (color) => {
    if(props.movesLeft>0){
      props.changeState(color)
      props.decreaseMoves()
    }
  }
  var buttons = props.colors.map((color, index) => <button className='buttonesh' style = {{backgroundColor: color,borderRadius: '50%', height: '40px',width: '40px',fontSize:'9px'}} key={index} onClick={() => handleClick(color)}>{}</button>)
  
  return(
   <>
    <div className='Main-Head'>
      
      <div style={controlStyle}>
        {buttons}
      </div>
      <div>
        <button className = 'resetbutton' onClick={props.resetBoard}>Reset</button>
      </div>
      <div>
        <p className='moves'>Moves left : <span className='number'>{props.movesLeft}</span></p>
        <p className='moves'>Level : <span className='level'>{props.level+1}</span></p>
      </div>

    </div>
  </> 
  )
}      
export default App;